<template>
    <section>
        <div class="container flex flex-col lg:flex-row gap-8 md:gap-16">
            <div class="w-full lg:w-1/2 mt-12 pb-5">
                <GlobalBreadcrumb :breadcrumb="breadcrumb" isLight />
                <h2>
                    <v-content
                        option_key="place_to_buy_heading"
                        class="text-36px lg:text-45px xl:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-semibold my-3"
                    >
                    </v-content>
                </h2>
                <v-content option_key="place_to_buy_subHeading" class="text-blacks-90 text-xl"></v-content>
                <div class="flex flex-col md:flex-row items-center gap-3 mt-8">
                    <div class="shadow-custom-input w-full">
                        <label for="province" class="text-sm font-medium text-blacks-70">Tỉnh/Thành phố</label>
                        <Selection
                            v-model:itemModel="proviceValue"
                            :data="dataProvince"
                            placeholder="Chọn tỉnh/thành phố"
                        />
                    </div>
                    <div class="shadow-custom-input w-full">
                        <label for="district" class="text-sm font-medium text-blacks-70">Quận/Huyện</label>
                        <Selection
                            v-model:itemModel="districtValue"
                            :data="dataDistrict"
                            :disabled="true"
                            placeholder="Chọn quận/huyện"
                        />
                    </div>
                </div>
                <div class="mt-10 w-full h-full">
                    <div
                        class="flex items-center gap-3 text-white bg-blacks-70 p-3 rounded-tl-1 rounded-tr-1 sticky top-0"
                    >
                        <i class="i-ic:outline-location-on text-xl"></i>
                        <v-content class="font-semibold text-xl w-full" option_key="place_to_buy_titleShowRoom">
                        </v-content>
                    </div>
                    <div class="bg-[#F3F3F3]">
                        <div
                            v-if="dataShowRoom && dataShowRoom.length > 0"
                            class="px-6 py-4 space-y-3 h-[calc(100vh-160px)] overflow-auto custom-scrollbar2 mr-2"
                        >
                            <div
                                v-for="(item, index) in dataShowRoom"
                                :class="activeBox == index ? 'activeBox' : 'hover:scale-101 duration-200'"
                                @click=";(address = item.address), (activeBox = index)"
                                class="bg-white px-4 py-3 text-sm space-y-3 shadow-custom cursor-pointer overflow-hidden"
                            >
                                <p>
                                    <span class="text-blacks-30">Cửa hàng: &nbsp</span>
                                    <span class="text-blacks-70">{{ item?.title }}</span>
                                </p>
                                <p>
                                    <span class="text-blacks-30">Địa chỉ: &nbsp</span>
                                    <span class="text-blacks-70">{{ item?.address }}</span>
                                </p>
                                <p>
                                    <span class="text-blacks-30">Mã đại lý: &nbsp</span>
                                    <span class="text-blacks-70">{{ item?.code }}</span>
                                </p>
                            </div>
                        </div>
                        <div v-else class="px-6 py-4 text-lg">Không có Showroom dựa theo địa chỉ trên.</div>
                    </div>
                </div>
            </div>
            <div class="h-100 lg:h-auto md:w-1/2">
                <div class="break-container !ml-0 !mt-0 h-full">
                    <div class="mapouter">
                        <div class="gmap_canvas">
                            <iframe
                                class="gmap_iframe"
                                width="100%"
                                frameborder="0"
                                scrolling="no"
                                marginheight="0"
                                marginwidth="0"
                                @load="checkLoad"
                                :src="`https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=${mapAddress}&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed`"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts"></script>

<style></style>
<script setup lang="ts">
import Selection from './Selection'
import { useAgency } from '../../composables/agency/index'
const { filterdAgency } = useAgency()
const breadcrumb = ref([
    {
        title: 'Trang chủ',
        url: '/'
    },
    {
        title: 'Tìm nơi mua'
    }
])
const provice = getProvince()
const dataProvince = ref([])
const dataDistrict = ref([])
const dataProvinceDefault = ref()
const dataDistrictDefault = ref()
const proviceValue = ref({})
const districtValue = ref({})
const dataShowRoom = ref([])

const address = ref(dataShowRoom.value[0]?.address ?? '')
const mapAddress = computed(() => address.value)

const title = ref()
const activeBox = ref(0)

watch(
    () => proviceValue.value,
    (newValue) => {
        if (newValue.code) {
            dataDistrict.value = searchDistrict(newValue.code)
            dataDistrictDefault.value = searchDistrict(newValue.code)
            filterdAgency({ province: proviceValue.value.name, district: districtValue.value.name }).then((data) => {
                dataShowRoom.value = data
                address.value = dataShowRoom.value[0]?.address ?? ''
                activeBox.value = 0
            })
        }
    }
)

watch(
    () => districtValue.value,
    (newValue) => {
        if (newValue.code) {
            filterdAgency({ province: proviceValue.value.name, district: districtValue.value.name }).then((data) => {
                dataShowRoom.value = data
                address.value = dataShowRoom.value[0]?.address ?? ''
                activeBox.value = 0
            })
        }
    }
)
watchDebounced(
    () => proviceValue.value.name,
    (newValue) => {
        if (!newValue) {
            districtValue.value = {}
            dataDistrict.value = []
            filterdAgency({ query: { limit: -1 } }).then((data) => {
                dataShowRoom.value = data
                address.value = dataShowRoom.value[0]?.address ?? ''
                activeBox.value = 0
            })
            return (dataProvince.value = dataProvinceDefault.value)
        }
        dataProvince.value = dataProvinceDefault.value.filter((item: any) => {
            return item.name.toLowerCase().includes(newValue.toLowerCase())
        })
    },
    { debounce: 500 }
)
watchDebounced(
    () => districtValue.value.name,
    (newValue) => {
        if (!newValue && proviceValue.value.code) {
            filterdAgency({ province: proviceValue.value.name }).then((data) => {
                dataShowRoom.value = data
            })
            return (dataDistrict.value = dataDistrictDefault.value)
        }

        dataDistrict.value = dataDistrictDefault.value.filter((item: any) => {
            return item.name.toLowerCase().includes(newValue.toLowerCase())
        })
    },
    { debounce: 500 }
)

onMounted(async () => {
    dataShowRoom.value = await filterdAgency({ query: { limit: -1 } })
    address.value = dataShowRoom.value[0].address
    dataProvince.value = provice
    dataProvinceDefault.value = provice
})
</script>

<style scoped>
.activeBox {
    @apply b-1 b-blacks-30;
}
.custom-input {
    @apply w-full py-3 px-3.5 b-1 b-blacks-10;
}
.shadow-custom {
    box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.1);
}
.shadow-custom-input {
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.mapouter {
    position: relative;
    text-align: right;
    width: 100%;
    height: 100%;
}
.gmap_canvas {
    overflow: hidden;
    background: none !important;
    width: 100%;
    height: 100%;
}
.gmap_iframe {
    height: 100% !important;
}
</style>
