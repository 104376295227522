<template>
    <section class="bg-[#F8F8F8] relative">
        <div id="section-contact-us" class="absolute top-1/2"></div>

        <div class="container py-15 lg:py-24 flex flex-col lg:flex-row gap-16">
            <div class="hidden lg:block w-1/2">
                <div class="h-full w-full">
                    <v-image
                        option_key="place_to_buy_imageBookMeeting"
                        :width="1000"
                        class="w-full h-full object-cover"
                    />
                </div>
            </div>

            <div class="w-full lg:w-1/2">
                <v-content
                    option_key="place_to_buy_titleBookMeeting"
                    class="text-36px lg:text-48px sm:leading-12 lg:leading-16 text-blacks-100 font-semibold my-3"
                >
                </v-content>
                <v-content
                    option_key="place_to_buy_subTitleBookMeeting"
                    class="text-blacks-90 text-xl mt-4"
                ></v-content>
                <form @submit.prevent="sendForm" class="placeholder-addCart space-y-4 mt-8">
                    <div class="relative flex flex-col sm:flex-row items-center gap-3 w-full">
                        <!-- <div class="w-full">
                            <label
                                for="contact_place_first_name"
                                :class="errorFirstName ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Tên</label
                            >
                            <input
                                type="text"
                                name="first_name"
                                id="contact_place_first_name"
                                :class="
                                    errorFirstName ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                "
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.first_name"
                                @input="infoUser.first_name = capitalizeFirstLetterOfWords(infoUser.first_name)"
                                placeholder="Tên"
                            />
                        </div> -->
                        <div class="w-full">
                            <label
                                for="contact_place_full_name"
                                :class="errorFirstName ? '!text-error-01' : ''"
                                class="text-sm text-blacks-70 font-medium"
                                >Họ và tên</label
                            >
                            <input
                                type="text"
                                name="full_name"
                                :class="
                                    errorFirstName ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''
                                "
                                id="contact_place_full_name"
                                class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                                v-model="infoUser.full_name"
                                @input="infoUser.full_name = capitalizeFirstLetterOfWords(infoUser.full_name)"
                                placeholder="Tên"
                            />
                        </div>
                    </div>

                    <div class="relative">
                        <label
                            for="contact_place_email"
                            :class="errorEmail ? '!text-error-01' : ''"
                            class="text-sm text-blacks-70 font-medium"
                            >Email</label
                        >
                        <input
                            type="text"
                            name="email"
                            id="contact_place_email"
                            :class="errorEmail ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                            class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                            v-model="infoUser.email"
                            placeholder="email@company.com"
                        />
                    </div>

                    <div class="relative">
                        <label
                            for="contact_place_phone"
                            :class="errorPhone ? '!text-error-01' : ''"
                            class="text-sm text-blacks-70 font-medium"
                            >Số điện thoại</label
                        >
                        <input
                            type="text"
                            name="phone"
                            id="contact_place_phone"
                            :class="errorPhone ? '!placeholder:text-error-01/80 border-error-01 !text-error-01' : ''"
                            class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                            v-model="infoUser.phone"
                            placeholder="(+84) 0000 00000"
                        />
                    </div>
                    <div class="shadow-custom-input w-full">
                        <label
                            for="province"
                            :class="errorProvince ? '!text-error-01' : ''"
                            class="text-sm font-medium text-blacks-70"
                            >Tỉnh/Thành phố</label
                        >
                        <Selection
                            v-model:itemModel="proviceValue"
                            v-model:checkEmpty="errorProvince"
                            :data="dataProvince"
                            placeholder="Chọn tỉnh/thành phố"
                        />
                    </div>
                    <div class="relative">
                        <label for="contact_place_message" class="text-sm text-blacks-70 font-medium">Lời nhắn</label>
                        <textarea
                            name="message"
                            rows="4"
                            cols="50"
                            id="contact_place_message"
                            v-model="infoUser.message"
                            class="w-full py-2.5 px-3.5 b-1 b-blacks-10 mt-1.5"
                            placeholder="Để lại ý kiến của bạn"
                        />
                    </div>
                    <div class="">
                        <label class="flex items-start gap-3 checkbox-custom cursor-pointer">
                            <input
                                type="checkbox"
                                name=""
                                v-model="isChecked"
                                :class="errorChecked ? '!border-red-500' : ''"
                                class="select-agree flex-none cursor-pointer"
                            />
                            <p :class="errorChecked ? 'text-red-500' : ''" class="text-sm xl:text-base text-blacks-70">
                                Bạn đồng ý với <a href="#" class="underline">chính sách bảo mật</a> của chúng tôi.
                            </p>
                        </label>
                    </div>
                    <button
                        class="btn-effect-dark w-full b-1 b-blacks-90 text-white bg-blacks-100 text-lg font-medium py-3 xl:py-5 text-center cursor-pointer block"
                    >
                        <p v-if="!loading">Gửi</p>
                        <GlobalLoadingSearch v-else class="mx-auto" />
                    </button>
                    <div v-if="statusSuccessForm" class="flex flex-col items-center gap-2">
                        <i class="i-ic:baseline-check-circle-outline text-4xl bg-[#01AB56]"></i>
                        <p class="text-xl font-bold text-red-500">Đặt lịch hẹn thành công</p>
                        <p class="text-blacks-50 text-center text-lg">
                            Chúng tôi sẽ liên hệ bạn trong thời gian sớm nhất !
                        </p>
                    </div>
                </form>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useReCaptcha } from 'vue-recaptcha-v3'
import Selection from './Selection.vue'
const infoUser = ref({
    phone: '',
    full_name: '',
    email: '',
    message: ''
})
const provice = getProvince()
const dataProvince = ref([])
const dataProvinceDefault = ref()
const proviceValue = ref({})

const errorPhone = ref(false)
const errorFirstName = ref(false)
const errorLastName = ref(false)
const errorEmail = ref(false)
const errorChecked = ref(false)
const errorProvince = ref(false)
const isChecked = ref()
const loading = ref(false)
const checkProvince = () => {
    if (proviceValue.value.name) {
        errorProvince.value = false
    } else {
        errorProvince.value = true
    }
}
watchDebounced(
    () => proviceValue.value.name,
    (newValue) => {
        checkProvince()
        if (!newValue) {
            return (dataProvince.value = dataProvinceDefault.value)
        }
        dataProvince.value = dataProvinceDefault.value.filter((item: any) => {
            return item.name.toLowerCase().includes(newValue.toLowerCase())
        })
    },
    { debounce: 500 }
)

const recaptchaInstance = useReCaptcha()
const recaptcha = async () => {
    // optional you can await for the reCaptcha load
    await recaptchaInstance?.recaptchaLoaded()

    // get the token, a custom action could be added as argument to the method
    const token = await recaptchaInstance?.executeRecaptcha('login')

    return token
}

const convertedPhoneNumber = () => {
    if (infoUser.value.phone !== '' && infoUser.value.phone[0] === '0') {
        var convertedPhoneNumber = infoUser.value.phone?.substring(1)
        infoUser.value.phone = '+84' + convertedPhoneNumber
    }
}
const checkPhone = () => {
    const vnf_regex = /((09|03|07|08|05)+([0-9]{8})\b)/g
    if (infoUser.value.phone) {
        const convertedPhoneNumber = infoUser.value.phone.replace(/^\+84/, '0')
        if (vnf_regex.test(convertedPhoneNumber)) {
            errorPhone.value = false
        } else {
            errorPhone.value = true
        }
    } else {
        errorPhone.value = true
    }
}
const checkName = () => {
    if (infoUser.value.full_name) {
        errorFirstName.value = false
    } else {
        errorFirstName.value = true
    }
}

const checkEmail = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    if (emailRegex.test(infoUser.value.email)) {
        errorEmail.value = false
    } else {
        errorEmail.value = true
    }
}

const checkChecked = () => {
    if (isChecked.value) {
        errorChecked.value = false
    } else {
        errorChecked.value = true
    }
}
watch(
    () => isChecked.value,
    () => {
        checkChecked()
    }
)

const statusSuccessForm = ref(false)
const statusFailForm = ref(false)

const sendForm = async (): Promise<void> => {
    checkPhone()
    checkName()
    checkEmail()
    checkChecked()
    checkProvince()

    if (
        !errorFirstName.value &&
        !errorPhone.value &&
        !errorEmail.value &&
        !errorChecked.value &&
        !errorProvince.value
    ) {
        loading.value = true

        statusSuccessForm.value = false
        console.log('error-pointment')
        const token = await recaptcha()
        fetch(`/api/appointment?response=${token}`, {
            method: 'POST',
            body: JSON.stringify({
                ...infoUser.value,
                province: proviceValue.value.name
            })
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                if (data.code == 201) {
                    loading.value = false
                    statusFailForm.value = false
                    statusSuccessForm.value = true
                    setTimeout(() => {
                        statusSuccessForm.value = false
                    }, 10000)

                    infoUser.value = {
                        phone: '',
                        full_name: '',
                        email: '',
                        message: ''
                    }
                    errorChecked.value = false
                    errorProvince.value = false
                } else {
                    statusFailForm.value = true
                }
            })

            .catch((error) => {
                console.log('🚀 ~ sendForm ~ error:', error)
                statusFailForm.value = true
            })
    }
}
onMounted(() => {
    dataProvince.value = provice
    dataProvinceDefault.value = provice
})
</script>

<style></style>
