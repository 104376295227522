<template>
    <div class="overflow-hidden">
        <SectionSearchAdd />
        <SectionBookContact />
    </div>
</template>

<script setup lang="ts">
import SectionSearchAdd from './SectionSearchAdd.vue'
import SectionBookContact from './SectionBookContact.vue'
onMounted(() => {
    document.querySelector('html')?.classList.add('scroll-smooth')
})
onBeforeUnmount(() => {
    document.querySelector('html')?.classList.remove('scroll-smooth')
})

useDirectusCollectionSeo('place_to_buy', {})
</script>

<style></style>
